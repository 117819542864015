/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bookmarks-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 4a2 2 0 012-2h6a2 2 0 012 2v11.5a.5.5 0 01-.777.416L7 13.101l-4.223 2.815A.5.5 0 012 15.5z"/><path pid="1" d="M4.268 1A2 2 0 016 0h6a2 2 0 012 2v11.5a.5.5 0 01-.777.416L13 13.768V2a1 1 0 00-1-1z"/>',
    },
});
